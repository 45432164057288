import '../styles/globals.scss'
import { SessionProvider } from "next-auth/react"

import { Inter, Lexend } from 'next/font/google'
import localFont from 'next/font/local'
import clsx from 'clsx'

const satoshi = localFont({ src: '../fonts/satoshi.ttf', subsets: ['latin'], variable: '--satoshi-font'})

const inter = Inter({
  subsets: ['latin'],
  variable: '--inter-font',
})
const lexend = Lexend({
  subsets: ['latin'],
  variable: '--lexend-font',
})


function MyApp({ Component, pageProps: { session, ...pageProps }, err }) {
  return (
    <SessionProvider session={session}>
        <main className={clsx(inter.variable, lexend.variable, satoshi.variable, "font-sans")}>
          <Component {...pageProps} err={err} />
        </main>
    </SessionProvider>
  )
}

export default MyApp
